import Head from 'next/head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Container from '../components/Container'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Typography from '../components/Typography'

const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const TextContainer = styled(Container)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 18px;
`

const NotFoundPage = () => {
  const { t } = useTranslation('common')

  return (
    <PageContainer>
      <Head>
        <meta charSet="utf-8" />
        <title>DreamCar | Not found</title>
        <meta name="description" content="TBD" />
      </Head>
      <Header simple />
      <TextContainer thin>
        <Typography.H2>{t('page_not_found')}</Typography.H2>
      </TextContainer>
      <Footer />
    </PageContainer>
  )
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default NotFoundPage
